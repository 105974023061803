import React from "react";
import "../scss/footer.scss";
import { AiFillYoutube } from "react-icons/ai";
import { FaDiscord, FaTelegram } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";
import { FiGlobe } from "react-icons/fi";
import logo from "../assests/images/Logo White.png"
import { Instagram } from "@material-ui/icons";
const Footer = () => {
  return (
    <footer id="foooter">
      <div className="container">


        <div className="footer-end">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <div className="mid">
            <h4>Copyright © 2023 Reverse Protocol, All Rights Reserved.</h4>
          </div>
          <div className="footer-icons">


            <div className="icon">
              <a href="">
                <AiOutlineTwitter />
              </a>
            </div>

            <div className="icon">
              <a href="h">
                <AiFillInstagram />
              </a>
            </div>

            <div className="icon">
              <a href=" ">
                <FaTelegram />
              </a>
            </div>

            {/* <AiFillInstagram/> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
