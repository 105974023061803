import React, { useEffect, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "../scss/aboutnft.scss";
import { Parallax, Background } from "react-parallax";
// import img from "./layer2.png";
import Vid from "../assests/RBH Anim[NEW].mp4"
import { FaDiscord } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaRedditAlien } from "react-icons/fa";
import { BiChevronRight } from "react-icons/bi";
import { gsap } from "gsap";
import { TimelineLite, Power4 } from "gsap/gsap-core";
import { ScrollTrigger } from "gsap/all";
import imgtt from "../assests/images/token.png"
gsap.registerPlugin(ScrollTrigger);
const AboutNft = () => {
  const img = useRef(null);
  const btn = useRef(null);
  const p = useRef(null);
  const tll = new gsap.timeline();
  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 100,
    });
  }, []);

  return (
    <>
      <section id="about" className="about">
        <div className="container">
          <div className="big__heading">
            Tap into the capabilities of <span>REVERSE</span>&nbsp;
            with the $REVERSE launchpad
          </div>
          <div className="about-content">
            <div className="about-img">
              <img
                src={imgtt}
                alt=""
              />
            </div>
            <div className="about-h">
              {/* <div className="wrap-heading">
                <div className="heading">
                  <h6>About us</h6>
                </div>
              </div> */}
              {/* <h2>High Quality NFT Collections</h2> */}
              <div className="para">
                <p> Reverse Protocol stands as a groundbreaking, secure, and adaptive AI-powered launchpad designed to instill confidence in investors seeking to navigate the world of Altcoins.</p>
                <p style={{ color: '#fff' }}> our platform provides a shield of assurance, ensuring that your investment journey is both protected and adaptable to the dynamic crypto landscape. Whether you're a seasoned investor or just starting out,</p>
                <p> Reverse Protocol is your steadfast partner in making informed and secure investment decisions in the world of alternative cryptocurrencies.:</p>


              </div>
              <div className="boxes">
                <div className="item">
                  <img src="https://i.gyazo.com/0810feb32f639c1fc9f37f786dfad025.png" alt="" />
                  <p>ETH Reflections</p>
                </div>
                <div className="item">
                  <img src="https://i.ibb.co/vc1QmQn/0f0be9229c3edb20ca6b5d78fbe7ce0e-1.png" alt="" />
                  <p>$REVERSE Holder Access</p>
                </div>
                <div className="item">
                  <img src="https://i.gyazo.com/24765eca500429d706ce3eb89d3fd831.png" alt="" />
                  <p>Defi Gift Cards</p>
                </div>
                <div className="item">
                  <img src="https://i.ibb.co/jR4046z/a410c625a17e72403c2f37d4d9a86595.png" alt="" />
                  <p>Self-Sustainable System</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>

    </>
  );
};

export default AboutNft;
