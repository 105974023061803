import React, { useState, useEffect, useRef } from "react";
import "./scss/normalize.css";
import Banner from "./components/Banner";
// import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import Roadmap from "./components/Roadmap";
import Teams from "./components/Teams";
import Faq from "./components/Faq";
import Collection from "./components/Collection";
import "./scss/reset.css";
import AboutNft from "./components/AboutNft";
import Info from "./components/Info";
// import Contact from "./components/Contact";
import mainBg from "./components/main-bg.f222c948.png";
import bottom from "./components/bott.jpg";
import Special from "./components/Special";
import Join from "./components/Join";
import Partnered from "./components/Partnered";
import Testimonials from "./components/Testimonials";
import Token from "./components/Token";
import Loader from "./components/Loader";
// import bottom2 from "./components/companion-bg.2c17497b.jpg";
var Spinner = require("react-spinkit");
function App() {
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#fff");
  const containerRef = useRef(null);



  return (
    <>
      <Banner />

      <AboutNft />
      <Special />
      {/* <Join /> */}
      {/* <Collection /> */}
      <Token />
      {/* <Roadmap /> */}

      {/* <Info /> */}

      {/* <Teams /> */}
      {/* <Partnered /> */}
      {/* <Testimonials /> */}
      <Faq />
      <Footer />
    </>
  );
}

export default App;
