import React, { useEffect, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "../scss/token.scss";
import { FaCheckCircle } from "react-icons/fa";
import { Parallax, Background } from "react-parallax";
import { gsap } from "gsap";
import { TimelineLite, Power3 } from "gsap/gsap-core";
import { ScrollTrigger } from "gsap/all";
import toke from "../assests/images/tokensAsset_4.png"
gsap.registerPlugin(ScrollTrigger);
// const data = [2, 15, 3, 10, 12, 8, 4, 40];
const data = [
  {
    name: "Marketing and Advertising: 10%",
    percent: 10,
  },
  {
    name: "Development: 3%",
    percent: 3,
  },
  {
    name: "Partnerships: 15%",
    percent: 15,
  },
  {
    name: "Liquidity: 2%",
    percent: 2,
  },
];

const data2 = [
  {
    name: "Accounting & Legal: 12%",
    percent: 12,
  },
  {
    name: "SMM Community Leaders: 8%",
    percent: 8,
  },
  {
    name: "Audits: 10%",
    percent: 10,
  },
  {
    name: "Distribution: 40%",
    percent: 40,
  },
];
const Token = ({ ease }) => {
  const ite = useRef(null);
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  useEffect(() => {
    gsap.from(".wid", 2, {
      width: 0,
      scrollTrigger: {
        trigger: ".itemll",
        start: "top 110%",
        end: "bottom 200% ",
        toggleActions: "restart complete reverse reset",
      },
    });
    gsap.to(".mid-", 3, {
      scale: 1,
      ease: ease,
      scrollTrigger: {
        trigger: ".itemll",
        start: "top 110%",
        end: "bottom 200% ",
        toggleActions: "restart complete reverse reset",
      },
    });
  }, []);
  return (
    // <Parallax
    //   blur={{ min: -15, max: 15 }}
    //   bgImage={img}
    //   bgImageAlt="the dog"
    //   strength={400}
    //   bgClassName={"roll"}
    // >

    // </Parallax>
    <section className="token" id="token">
      <div className="container">
        <div className="big__heading">
          Tokenomics
        </div>
        <div className="about-content">
          <div className="about-img">
            <img
              src={toke}
              alt=""
            />
          </div>
          <div className="about-h">
            {/* <div className="wrap-heading">
                <div className="heading">
                  <h6>About us</h6>
                </div>
              </div> */}
            {/* <h2>High Quality NFT Collections</h2> */}
            <div className="para">

              <p style={{ color: '#fff' }}>  $REVERSE will implement a 5% tax strategy designed to support ongoing development and ensure the project's growth and success.</p>
              <p> Of this, 2% of the tax will be dedicated to funding development initiatives, facilitating ongoing improvements, upgrades, and overall project development.</p>
              <p>The remaining 3% of the tax will be allocated to marketing efforts and strategic activities aimed at expanding Reverse's presence and visibility within the cryptocurrency market.</p>
              <ul>
                <li>Supply: 50.000.000 REVERSE</li>
                <li>Decimals: 9</li>
                <li>Liquidity locked for 1 year (September 2024)</li>
              </ul>
              <p className="contract">CA: 0x0000000000000000000000000000000000000000</p>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
};

export default Token;
