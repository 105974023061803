import React, { useEffect, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "../scss/banner.scss";
import Marquee from "react-fast-marquee";
import Navigation from "./Navigation";
import { BannerImages } from "../assests/data";
import { gsap } from "gsap";
import { TimelineLite, Power4 } from "gsap/gsap-core";
// import img1 from '"/img_slide_Home01.png" '

const Banner = ({ timeline, ease }) => {
  useEffect(() => {
    AOS.init({});
  }, []);
  return (
    <>
      <Navigation timeline={timeline} ease={ease} />
      <section className="banner">
        <div className="container">
          <div className="banner-h">
            <div className="banner-main-h">
              <h1>Revive. Renew.&nbsp;<div className="div">Reverse.</div> </h1>

            </div>
            <p>
              Reverse Protocol is a cutting-edge launchpad for meme coins, boasting top-tier security features such as robust KYC verification and AI-driven audit processes to ensure a safe and transparent ecosystem.
            </p>
            <div className="banner-btn">
              <div className="btn-main">
                <a href="#">
                  <div>Watch the video</div>
                </a>
              </div>
              <div className="btn-main">
                <a href="#">
                  <div>Enter DAPP</div>
                </a>
              </div>
            </div>
          </div>

          <div className="bottom__grid">
            <div className="item active__item">
              <div className="first">
                <div className="circle"></div>
                <div>
                  <p>Memecoin1</p>
                  <p>MC1</p>
                </div>
              </div>
              <div className="second">
                RELAUNCHING
              </div>
              <div className="third">
                <span></span>
              </div>
              <div className="fourth">
                <p>$1428.88</p>
                <p>$14.88</p>
              </div>
            </div>
            <div className="item ">
              <div className="first">
                <div className="circle"></div>
                <div>
                  <p>Memecoin1</p>
                  <p>MC1</p>
                </div>
              </div>
              <div className="second">
                RELAUNCHING
              </div>
              <div className="third">
                <span></span>
              </div>
              <div className="fourth">
                <p>$1428.88</p>
                <p>$14.88</p>
              </div>
            </div>
            <div className="item ">
              <div className="first">
                <div className="circle"></div>
                <div>
                  <p>Memecoin1</p>
                  <p>MC1</p>
                </div>
              </div>
              <div className="second">
                RELAUNCHING
              </div>
              <div className="third">
                <span></span>
              </div>
              <div className="fourth">
                <p>$1428.88</p>
                <p>$14.88</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
