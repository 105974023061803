import { FaDiscord } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaRedditAlien } from "react-icons/fa";
import partner1 from "../assests/images/yt/media-15.svg"
import partner2 from "../assests/images/yt/media-19.svg"
import partner3 from "../assests/images/yt/media-2.svg"
import partner4 from "../assests/images/yt/media-20.svg"
import partner5 from "../assests/images/yt/media-21.svg"
import partner6 from "../assests/images/yt/media-7.svg"
import partner7 from "../assests/images/yt/media-9.svg"
export const nav = [
  {
    name: "Home",
    path: "#banner",
    ref: "nav2",
  },

  {
    name: "About",
    path: "#about",
    ref: "nav4",
  },
  {
    name: "Token",
    path: "#token",
    ref: "nav3",
  },
  {
    name: "Roadmap",
    path: "#special",
    ref: "nav1",
  },


];

export const roadmap = [
  {
    id: "01",
    head: "Development and Launch",
    points: [
      "Idea Creation: Develop the concept and key features of CoinMatch, including the use of AI algorithms for smarter crypto trading.",
      "Web Development: Build a user-friendly website for CoinMatch that provides easy access to the platform's features and functionalities. ",
      "Contract Creation: Develop a smart contract on the Binance Smart Chain that governs the distribution and usage of CoinMatch tokens.",
      "Audit and KYC: Conduct a comprehensive KYC process for all potential users and partners, and ensure that CoinMatch is fully audited for transparency and trustworthiness.",
      "Social Launch: Initiate a robust marketing strategy to generate buzz and awareness for CoinMatch, including social media marketing, influencer marketing, content marketing, and PR outreach.",
      "Presale on Pinksale: Launch the presale of CoinMatch tokens on Pinksale, allowing early investors to participate in the project.",
    ],
    date: "2022",
    img: "/images/roadmap/1.png",
  },
  {
    id: "02",
    head: "Expansion and Outreach",
    points: [
      "PancakeSwap Listing: List CoinMatch on PancakeSwap for wider accessibility.",
      "CoinMarketCap and CoinGecko Listing: Apply for CoinMarketCap and CoinGecko listing to increase visibility and credibility.",
      "Community Voice Chats: Host community voice chats to enhance engagement and gather feedback.",
      "Whitepaper Release: Publish a comprehensive whitepaper that outlines CoinMatch's technology, features, and value proposition.",
      "Press Release Worldwide: Reach out to relevant media outlets and publications to secure coverage and press mentions for CoinMatch.",
      "Influencer Marketing: Partner with influential crypto thought leaders and YouTubers to help promote CoinMatch and generate buzz.",
      "Social Media Promotion: Use social media platforms to further engage with the community and promote CoinMatch.",
    ],
    date: "2022",
    img: "/images/roadmap/1.png",
  },
  {
    id: "03",
    head: "Mobile App Development and Expansion",
    points: [
      "Mobile App Development: Develop the CoinMatch app for mobile devices to enable easy access and trading on-the-go.",
      "Team Expansion: Expand the CoinMatch team to include experienced developers, data scientists, and marketing professionals to further develop and enhance the platform.",
      "Celebrity Marketing: Utilize celebrity endorsements to increase CoinMatch's visibility and reach.",
      "Advertising Platform: Build an advertising platform that allows users to easily promote their projects to a wide audience.",
      "Mobile App Release: Launch the CoinMatch app on the Play Store and App Store for easy access on mobile devices.",
      "Exchange Listings: List CoinMatch on major cryptocurrency exchanges for increased liquidity and accessibility.",
    ],
    date: "2022",
    img: "/images/roadmap/1.png",
  },
];

export const Nfts = [
  "/images/nft/1.png",
  "/images/nft/2.png",
  "/images/nft/3.png",
  "/images/nft/4.png",
  "/images/nft/5.png",

  "/images/nft/7.png",
  "/images/nft/8.png",
  "/images/nft/5.png",
  "/images/nft/10.png",
];

export const FAQ = [
  {
    q: "Reverse of the Degens: REVERSE Trading platform",
    ans: "We've crafted an ingenious DeFi Launchpad platform with precision, designed to streamline cryptocurrency trading across a wide range of blockchain networks. This is achieved by harnessing the inherent strength of our security integrations and ensuring a 100% locked liquidity pool.",
    date: "Q4 2024"
  },
  {
    q: "Empowering Your Financial Future: Introducing Reverse Protocol's DeFi Lending Platform",
    ans: "Experience a Revolutionary DeFi Lending Platform. Embrace the future of lending where innovation converges with opportunity, unlocking the potential for unparalleled growth and prosperity.",
    date: "Q1 2024"
  },


];
export const partners = [
  {
    src: partner1,
    link: ""
  },
  {
    src: partner2,
    link: ""
  },
  {
    src: partner3,
    link: ""
  },
  {
    src: partner4,
    link: ""
  },
  {
    src: partner5,
    link: ""
  },
  {
    src: partner6,
    link: ""
  },
  {
    src: partner7,
    link: ""
  },
]
export const team = [
  {
    name: "Lucian Petronel Potlog",
    position: "Founder",
    path: "/images/team/2.jpg",
    desc: "A visually talented MF. The man who first drew the concept of these exceptional Shutter Bots along with Artmarketeer. He is an amazing artist who adapted his talents to the digital age by being a professional graphic designer for over 10 years. Even as a young child he would draw on walls, but now he draws inspiration from the walls as he changed profession pursuing photography full time. He is one of Ireland’s best known street photographers with a following of 20k across his channels.",
    link: "https://linktr.ee/lucian.photography",
  },

  {
    name: "Claudiu Potlog",
    position: "Artist",
    path: "/images/team/2.jpg",
    desc: "As a child he must have looked up to his older brother as he followed his footsteps into the life of an artist. Educated in the traditional sense he has a master’s in graphic design. Him and his brother created the art of the NFT collection together probably arguing like brothers do (only because they want the best possible art work) Although he is a master at digital art he still has a huge passion for traditional art with huge canvases across his house that he painted. Most kids played with Lego these two played with pencils.",
    link: "https://linktr.ee/claudesign.art",
  },
  {
    name: "Conor D’Arcy",
    position: "Artist",
    path: "/images/team/3.webp",
    desc: "Visually unstable but visually passionate. A self-taught filmmaker who loves creating stories. Working freelance for over 3 years making the types of adverts that not all brands can use because they are a little unhinged. He has worked with many start- ups always making sure to be as fun as he is professional. A little bit weird but aren’t we all.",
    link: "https://linktr.ee/codarcfilm",
  },
  {
    name: "Karlo Turić",
    position: "Social Media Manager",
    path: "/images/team/4.jpg",
    desc: "This man makes Joe exotic look like an accountant. A lover of all exotic creatures and an expert in the NFT space. Karlo is an expert in the NFT space and with social media marketing, amassing over 150k across his channels. He is experienced in design, social media and discord management. Running a successful print on demand business for 3 years now. Actively trading and working in the NFT space. Experienced in design, social media and discord management.",
    link: "https://linktr.ee/exotickarlo",
  },
];
export const collection = [
  {
    name: "darknight",
    id: 1,
    desc: "An experienced graphic designer who has developed original IP with a diverse range of brands from indie collectives to creative projects.",

    path: "/images/nft/darknight.jpg",
    power: [
      {
        name: "strength",
        val: 70,
      },
    ],
  },
  {
    name: "sbc-mushroom",
    id: 2,
    desc: "An experienced graphic designer who has developed original IP with a diverse range of brands from indie collectives to creative projects.",

    path: "/images/nft/sbc-mushroom.jpg",
    power: [
      {
        name: "strength",
        val: 70,
      },
    ],
  },
  {
    name: "sbc-seeds",
    id: 3,
    desc: "An experienced graphic designer who has developed original IP with a diverse range of brands from indie collectives to creative projects.",

    path: "/images/nft/sbc-seeds.jpg",
    power: [
      {
        name: "strength",
        val: 70,
      },
    ],
  },
  {
    name: "sbc-sol-rocket",
    id: 4,
    desc: "An experienced graphic designer who has developed original IP with a diverse range of brands from indie collectives to creative projects.",

    path: "/images/nft/sbc-sol-rocket.jpg",
    power: [
      {
        name: "strength",
        val: 70,
      },
    ],
  },
  {
    name: "sol2",
    id: 5,
    desc: "An experienced graphic designer who has developed original IP with a diverse range of brands from indie collectives to creative projects.",

    path: "/images/nft/sol2.jpg",
    power: [
      {
        name: "strength",
        val: 70,
      },
    ],
  },
  {
    name: "solana01",
    id: 6,
    desc: "An experienced graphic designer who has developed original IP with a diverse range of brands from indie collectives to creative projects.",

    path: "/images/nft/solana01.jpg",
    power: [
      {
        name: "strength",
        val: 70,
      },
    ],
  },
  {
    name: "sol2-a",
    id: 7,
    desc: "An experienced graphic designer who has developed original IP with a diverse range of brands from indie collectives to creative projects.",

    path: "/images/nft/sol2-a.jpg",
    power: [
      {
        name: "strength",
        val: 70,
      },
    ],
  },
  {
    name: "sol7",
    id: 8,
    desc: "An experienced graphic designer who has developed original IP with a diverse range of brands from indie collectives to creative projects.",

    path: "/images/nft/sol7.jpg",
    power: [
      {
        name: "strength",
        val: 70,
      },
    ],
  },
  {
    name: "sol3",
    id: 9,
    desc: "An experienced graphic designer who has developed original IP with a diverse range of brands from indie collectives to creative projects.",

    path: "/images/nft/sol3.jpg",
    power: [
      {
        name: "strength",
        val: 70,
      },
    ],
  },
  {
    name: "sol6",
    id: 10,
    desc: "An experienced graphic designer who has developed original IP with a diverse range of brands from indie collectives to creative projects.",

    path: "/images/nft/sol6.jpg",
    power: [
      {
        name: "strength",
        val: 70,
      },
    ],
  },
];
