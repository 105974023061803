import React from "react";
import "../scss/special.scss";
const Special = () => {
  return (
    <>
      <section id="special" className="special">
        <div className="container">
          <div className="special-content">
            <div className="special-h">

              <h4>Liquidity,&nbsp;<span>100% Locked</span></h4>
              <div className="para">
                <p>We are committed to supporting our holders through the Reverse Protocol, always mindful of our strong community focus. Our dedication to giving back will shape the forefront of DeFi for generations to come.</p>
              </div>
            </div>

            <div className="special-grid">
              <div className="grid-iteam">
                <div className="iteam">


                  <div className="iteam-content">
                    <div className="icon-wrapper">
                      <div className="icon">
                        <img
                          src="https://i.gyazo.com/ef6711d2ff1d6b2ef211ff391df122d2.png"
                          alt=""
                        />
                      </div>{" "}
                    </div>
                    <h4>Free LP Deployment </h4>
                    <p> Contracts are launched via the launchpad after undergoing a thorough security process, including audits, KYC capabilities, penetration testing, and more.</p>
                  </div>
                </div>
              </div>
              <div className="grid-iteam">
                <div className="iteam">

                  <div className="iteam-content">
                    <div className="icon-wrapper">
                      <div className="icon">
                        <img
                          src="https://i.gyazo.com/d2dfae776d16e16f9042a5c1e59bcf4e.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <h4> 1% Auto LP Tax </h4>
                    <p>Each trade incurs a 1% automatic LP tax, which contributes to the Reverse Protocol (Platform).
                    </p>
                  </div>
                </div>
              </div>
              <div className="grid-iteam">
                <div className="iteam">

                  <div className="iteam-content">
                    <div className="icon-wrapper">
                      <div className="icon">
                        <img
                          src="https://i.gyazo.com/1074e075f796212a9861ab986366db4f.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <h4>Continuous Growth</h4>
                    <p>Reverse generates a prepetual cycle of liquidity. increasing LP while rewarding its holders in ETH.</p>
                  </div>
                </div>
              </div>
              <div className="grid-iteam">
                <div className="iteam">

                  <div className="iteam-content">
                    <div className="icon-wrapper">
                      <div className="icon">
                        <img
                          src="https://i.gyazo.com/0464d0eda9be1aed83ba64bd53ee5b4a.png"
                          alt=""
                        />
                      </div>


                    </div>
                    <h4>Value Locking </h4>
                    <p>A portion of the trading volume is converted into $REVERSE. reducing their circulation and adding more value to Reverse Protocol</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Special;
